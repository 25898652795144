import React from 'react'

const AI = () => {
  return (
    <>
    <div className="doctor-wrapper">
      <div className="container container-padd aiheight">
     <h2>AI</h2>
    </div>
    </div>
          </>
  )
}

export default AI