import { toast } from "react-toastify";
import Papa from "papaparse";
import * as XLSX from "xlsx";

// Function to call api
// api/apiUtils.js
export async function makeApiRequest(
  url,
  { method = "GET", data = null, token = null, apiKey = null }
) {
  try {
    let newOptions = {
      method,
      body: data,
    };

    const headers = {};

    if (token) {
      headers["x-access-token"] = token;
    }

    if (apiKey) {
      headers["x-api-key"] = apiKey;
    }

    if (data === null) {
      newOptions = newOptions;
    } else if (data instanceof FormData) {
      newOptions = { ...newOptions, body: data };
    } else {
      headers["Content-Type"] = "application/json";
      newOptions = { ...newOptions, body: JSON.stringify(data) };
    }

    newOptions.method = method;
    newOptions.headers = headers;

    const response = await fetch(
      process.env.REACT_APP_BASE_URL + url,
      newOptions
    );
    const processedData = await response.json();

    if (response.ok) {
      return processedData;
    } else {
      if (response.status === 401) {
        removeUserSession();
        window.location.href = "/";
      }
      throw new Error(processedData.message);
    }
  } catch (error) {
    throw error;
  }
}

export function getRole() {
  const userStr = localStorage.getItem("user");

  try {
    if (userStr) {
      const user = JSON.parse(userStr);

      if (user && user.roles && user.roles.length > 0) {
        return user.roles[0];
      }
    }
  } catch (error) {
    console.error("Error parsing user data:", error);
  }

  return false;
}

export function getId(currentRole) {
  const userStr = localStorage.getItem("user");

  let role = "";
  let user = {};
  try {
    if (userStr) {
      user = JSON.parse(userStr);
      if (user && user.roles && user.roles.length > 0) {
        role = user.roles[0];
      } else {
        role = false;
      }
    }
  } catch (error) {
    console.error("Error parsing user data:", error);
  }
  if (currentRole === role) {
    return user ? user.id : "";
  }
}

export function showTicketFields() {
  const permissions = {
    managerIdVal: false,
    teamIdVal: false,
    associateIdVal: false,
  };
  const userStr = localStorage.getItem("user");

  let role = "";
  try {
    if (userStr) {
      const user = JSON.parse(userStr);
      if (user && user.roles && user.roles.length > 0) {
        role = user.roles[0];
      } else {
        role = false;
      }
    }
  } catch (error) {
    console.error("Error parsing user data:", error);
  }

  if (
    role === "ROLE_ADMIN" ||
    role === "ROLE_SUPPORT" ||
    role === "ROLE_USER" ||
    role === "ROLE_QA"
  ) {
    return { ...permissions, managerIdVal: true };
  } else if (role === "ROLE_MANAGER") {
    return { ...permissions, teamIdVal: true };
  } else if (role === "ROLE_TEAM LEAD") {
    return { ...permissions, managerIdVal: true, associateIdVal: true };
  } else if (role === "ROLE_ASSOCIATE") {
    return {
      ...permissions,
      managerIdVal: true,
      teamIdVal: true,
      associateIdVal: true,
    };
  }

  // If no role matches, return the default permissions
  return permissions;
}

export function formatRelativeDate(dateString) {
  const now = new Date();
  const date = new Date(dateString);

  // Calculate the difference in milliseconds
  const diff = now - date;
  const diffInSeconds = Math.floor(diff / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);

  if (diffInSeconds < 60) {
    return "just now";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} m`;
  } else if (diffInHours < 24 && now.getDate() === date.getDate()) {
    const hoursAgo = diffInHours === 1 ? "h" : "h";
    return `${diffInHours} ${hoursAgo}`;
  } else if (diffInHours < 48) {
    return "yesterday";
  } else {
    // Format the date as 'YYYY-MM-DD'
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  }
}

// convert csv to object
export const parseCsvToObjectArray = async (csv) => {
  return new Promise((resolve, reject) => {
    Papa.parse(csv, {
      header: true, // Assumes the first row contains headers
      skipEmptyLines: true,
      complete: (result) => {
        resolve(result.data);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

export const readExcel = (file) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const data = e.target.result;

        // Use xlsx library to parse the Excel data
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert the sheet data to an array of objects
        const arrayOfObjects = XLSX.utils.sheet_to_json(sheet);

        console.log(arrayOfObjects);
        // Resolve the promise with the arrayOfObjects
        resolve(arrayOfObjects);
      };

      reader.onerror = function (error) {
        // Reject the promise if there is an error
        reject(error);
      };

      reader.readAsBinaryString(file);
    } else {
      // Reject the promise if no file is provided
      reject(new Error("No file provided"));
    }
  });
};

export function canAssign(id) {
  const permissions = {
    teamCan: true,
    managerCan: true,
    tlCan: true,
    agentCan: true,
  };
  const role = getRole();

  if (!role) {
    return permissions;
  } else if (role === "ROLE_ADMIN" || role === "ROLE_SUPPORT") {
    return { ...permissions, teamCan: false, managerCan: false };
  } else if (role === "ROLE_MANAGER") {
    return { ...permissions, tlCan: false, teamCan: false };
  } else if (role === "ROLE_TL") {
    return { ...permissions, agentCan: false };
  } else if (role === "ROLE_AGENT") {
    return {
      ...permissions,
      agentCan: false,
    };
  } else return permissions;
}

export function parseJSON(jsonString) {
  try {
    // Try to parse the JSON data
    const parsedData = JSON.parse(jsonString);
    return parsedData;
  } catch (error) {
    // Handle the case where the input is not valid JSON
    console.error("Error: Invalid JSON data");
    return null;
  }
}

export function handleFullName({ fullName, fname, lname }) {
  if (fullName) {
    return fullName.length > 15 ? fullName.substring(0, 15) + "..." : fullName;
  } else if (fname) {
    let fullNameStr = "";

    if (fname) {
      fullNameStr += fname + " ";
    }

    if (lname) {
      fullNameStr += lname;
    }

    return fullNameStr.length > 15
      ? fullNameStr.substring(0, 15) + "..."
      : fullNameStr;
  } else {
    return "NA";
  }
}

export const fullNameRoles = ["Role_DOCTOR", "Role_PATIENT"];

// Show Validation Error
export const showError = (error, touched) => {
  if (touched && error) {
    return <div className="error-msg">{error}</div>;
  }
  return null;
};

// return the token from the Localstorage
export const getToken = () => {
  return localStorage.getItem("accessToken") || null;
};

export function isPermitted(...values) {
  // console.log("CHECK", ...values);
  return values.every((value) => value === "1");
}

// remove the token and user from the Localstorage
export const removeUserSession = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");
};

// set the token and user from the Localstorage
export const setUserSession = (token, user) => {
  localStorage.setItem("accessToken", token);
  localStorage.setItem("user", JSON.stringify(user));
};

// Return the user data from the LocalStorage storage
export const getCurrentUserLT = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const userRole = () => {
  const user = getCurrentUserLT();
  if (user) {
    return user.roles[0];
  } else {
    return null;
  }
};

// Notification message
export const notify = (message, type = "success") => {
  const option = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  if (type === "success") {
    toast.success(message, option);
  } else if (type === "warn") {
    toast.warn(message, option);
  } else if (type === "error") {
    toast.error(message, option);
  }
};

// Dummy data
export const productTableData = (amount) => {
  const names = [
    "Iphone 14 pro",
    "Samsung S23 Ultra",
    "Realme 10 pro",
    "Google Pixel 5",
    "Vivobook s14",
    "Cobb Jeans",
    "Sparx Shoes",
    "Cannon DSLR",
    "Nike Shoes",
    "Redmi Note 10",
  ];

  const images = [
    "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80",
    "https://images.unsplash.com/photo-1545239351-ef35f43d514b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
    "https://images.unsplash.com/photo-1587614382346-4ec70e388b28?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    "https://images.unsplash.com/photo-1515343480029-43cdfe6b6aae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1528&q=80",
  ];
  let dummyArray = [];
  for (let i = 0; i < amount; i++) {
    dummyArray.push({
      srNo: i < 9 ? "0" + (i + 1) : `${i + 1}`,
      productName: names[Math.floor(Math.random() * 10)],
      productCode: 100000 + Math.floor(Math.random() * 1000000),
      productImages: images[Math.floor(Math.random() * 4)],
    });
  }
  return dummyArray;
};

export function getFormattedDate(dateString) {
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", options);

  const dayNumber = date.getDate();
  const suffix = getNumberSuffix(dayNumber);

  const [dayName, month, , year] = formattedDate.split(" ");

  return `${dayName} ${dayNumber}${suffix} ${month}, ${year}`;
}

function getNumberSuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }

  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

// export const getSerielNumber = (index, page = 0) => {
//   return `${index < 9 ? page : ""}${index + 1}`;
// };
export const getSerielNumber = (index, page = 0) => {
  const serialNumber = page * 10 + (index + 1);
  return serialNumber.toString().padStart(2, "0");
};

export const localDateFormat = (timeStamp) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (timeStamp) {
    const date = new Date(timeStamp);
    let day = 0;
    date.getDate() < 10 ? (day = "0" + date.getDate()) : (day = date.getDate());

    return day + " " + monthNames[date.getMonth()] + ", " + date.getFullYear();
  } else {
    return "dd-mm-yy";
  }
};

// reverse date
// export function reverseDate(dateString) {
//   const dateParts = dateString?.toString()?.split("-" || " ");
//   if (dateParts) {
//     const year = dateParts[0];
//     const month = dateParts[1];
//     const day = dateParts[2];
//     return `${day}-${month}-${year}`;
//   } else {
//     return "NA";
//   }
// }

export function reverseDate(dateString) {
  const monthHash = {
    jan: "01",
    feb: "02",
    mar: "03",
    apr: "04",
    may: "05",
    jun: "06",
    jul: "07",
    aug: "08",
    sep: "09",
    oct: "10",
    nov: "11",
    dec: "12",
  };
  const dateParts = dateString?.toString()?.split(" ");
  if (dateParts) {
    const year = dateParts[3];
    // const month = dateParts[1];
    const month = monthHash[dateParts[1].toLowerCase()] || dateParts[1];
    const day = dateParts[2];
    return `${day}-${month}-${year}`;
  } else {
    return "NA";
  }
}

// export const inputDate = (date) => {
//   const newDate = new Date(date);
//   const day =
//     newDate.getDay() < 10
//       ? "0" + (newDate.getDay() + 1)
//       : "" + (newDate.getDay() + 1);
//   const month =
//     newDate.getMonth() < 10
//       ? "0" + (newDate.getMonth() + 1)
//       : "" + (newDate.getMonth() + 1);
//   const year = newDate.getFullYear();
//   return day + "-" + month + "-" + year;
// };

export function inputDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Adding 1 to get the correct month (0 - 11)
  const year = date.getFullYear();

  const formattedDate = `${day.toString().padStart(2, "0")}-${month
    .toString()
    .padStart(2, "0")}-${year}`;

  return formattedDate;
}

export const getOrderStatus = (status) => {
  const allStatus = [
    "Draft",
    "Pending",
    "Payment Failed",
    "Cancel",
    "RTO",
    "Completed",
    "Shipped",
  ];
  return allStatus[status - 1];
};

export function convertDateFormat(dateStr) {
  // Create a new Date object with the input date string
  const date = new Date(dateStr);

  // Extract year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month as it's zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  // Concatenate the parts in the desired order with slashes
  const convertedDate = `${day}/${month}/${year}`;

  return convertedDate;
}

export function reverseDateFormat(dateStr) {
  if (!dateStr) return "";
  // Split the input date string into day, month, and year
  const [day, month, year] = dateStr && dateStr.split("/");

  // Create a new Date object using the reversed order of parts
  const date = new Date(`${year}-${month}-${day}`);

  // Extract year, month, and day from the Date object
  const reversedYear = date.getFullYear();
  const reversedMonth = String(date.getMonth() + 1).padStart(2, "0");
  const reversedDay = String(date.getDate()).padStart(2, "0");

  // Concatenate the parts in the desired order with dashes
  const convertedDate = `${reversedYear}-${reversedMonth}-${reversedDay}`;

  return convertedDate;
}

export const convertToCSV = (data) => {
  // Convert data to CSV format
  // Implement your CSV conversion logic here
  const header = Object.keys(data[0]).join(",");
  const rows = data.map((item) => Object.values(item).join(","));
  return header + "\n" + rows.join("\n");
};

export function truncateHTML(html, maxLength) {
  // Create a temporary element to parse the HTML
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;

  // Extract the text content and apply the character limit
  const textContent = tempElement.textContent || tempElement.innerText;
  if (textContent.length > maxLength) {
    return textContent.slice(0, maxLength) + "...";
  } else {
    return textContent;
  }
}

export const getFirstLetterName = function (string) {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

// export const inputHtmlDataformateChange = (params) => {
//   // console.log(params);
//   // if (!params) {
//   //   return '';
//   // }

//   // const inputDate = params;
//   // const parts = inputDate.split("/");

//   // // Rearrange the parts
//   // const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

//   // return formattedDate;
// };

export const formatCustomDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

// chatbot time chnages

export const formateChatTime = (params) => {
  const dateObject = new Date(params);
  const now = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = now - dateObject;

  if (timeDifference < 60000) {
    // Within the last minute
    return "just now";
  }

  // Extract date and time components
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");
  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");

  // Format the date and time to a more readable string
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
};

export const formateTimeWithChatEnd = (params) => {
  const dateObject = new Date(params);
  const now = new Date();

  // Extract date and time components
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");
  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");

  // Format the date and time to a more readable string with AM/PM
  const formattedTime = dateObject.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  // Check if the message is from today
  if (
    now.getFullYear() === dateObject.getFullYear() &&
    now.getMonth() === dateObject.getMonth() &&
    now.getDate() === dateObject.getDate()
  ) {
    return formattedTime;
  }

  // If it's not from today, return the date and time
  // return `${year}-${month}-${day} ${formattedTime}`;
  return ` ${formattedTime}`;
};

export const convertFormateDOB = (params) => {
  if (!params) return "";
  const parts = params?.split("/");
  const formattedDateofBirth = `${parts[2]}-${parts[0].padStart(
    2,
    "0"
  )}-${parts[1].padStart(2, "0")}`;
  return formattedDateofBirth;
};

// copy text

export const copyTextToClipboard = (params) => {
  const textToCopy = params;

  // Create a temporary input element
  const tempInput = document.createElement("input");
  tempInput.value = textToCopy;
  document.body.appendChild(tempInput);

  // Select the text in the input element
  tempInput.select();
  tempInput.setSelectionRange(0, 99999); // For mobile devices

  // Copy the text to the clipboard
  document.execCommand("copy");

  // Remove the temporary input element
  document.body.removeChild(tempInput);

  // console.log('Text copied to clipboard:', textToCopy);

  notify("Copied to clipboard ", "success");
};

// export const downloadFile = ({ fileUrl, fileName }) => {
//   const isPDF = fileName && fileName.toLowerCase().endsWith('.pdf');
//   const isExcel = fileName && (fileName.toLowerCase().endsWith('.csv') || fileName.toLowerCase().endsWith('.csv'));
//   const isDoc = fileName && (fileName.toLowerCase().endsWith('.docx') || fileName.toLowerCase().endsWith('.docx'));
//   const isZip = fileName && (fileName.toLowerCase().endsWith('.zip') || fileName.toLowerCase().endsWith('.zip'));
//   const isExcelXLSX = fileName && (fileName.toLowerCase().endsWith('.xlsx') || fileName.toLowerCase().endsWith('.xlsx'));
//   const isVideo = fileName && (fileName.toLowerCase().endsWith('.mp4') || fileName.toLowerCase().endsWith('.mp3'));
//   const isAudio = fileName && (fileName.toLowerCase().endsWith('.mp3') || fileName.toLowerCase().endsWith('.mp3'));
//   const isWebm = fileName && (fileName.toLowerCase().endsWith('.webm') || fileName.toLowerCase().endsWith('.webm'));
//   const isAPK = fileName && (fileName.toLowerCase().endsWith('.apk') || fileName.toLowerCase().endsWith('.apk'));
//   const isImage = fileName && (fileName.toLowerCase().endsWith('.image') || fileName.toLowerCase().endsWith('.image'));

//   if (isPDF || isExcel || isDoc || isZip || isExcelXLSX || isVideo || isAudio || isWebm || isAPK || !isImage) {
//     fetch(fileUrl)
//       .then(response => response.blob())
//       .then(blob => {
//         const url = window.URL.createObjectURL(new Blob([blob]));
//         const a = document.createElement('a');
//         a.href = url;
//         a.download = fileName || 'file';
//         document.body.appendChild(a);
//         a.click();
//         document.body.removeChild(a);
//         window.URL.revokeObjectURL(url);
//       })
//       .catch(error => notify('Error downloading file:', error));
//   } else {
//     notify('Unsupported file type', "error");
//   }
// };
export const downloadFile = ({ fileUrl, fileName }) => {
  const supportedFileTypes = [
    ".pdf",
    ".csv",
    ".docx",
    ".zip",
    ".xlsx",
    ".mp4",
    ".mp3",
    ".webm",
    ".apk",
    ".jpeg",
    ".jpg",
    ".png",
    ".xls",
  ];
  const fileExtension =
    fileName && fileName.toLowerCase().slice(fileName.lastIndexOf("."));

  if (supportedFileTypes.includes(fileExtension) || !fileExtension) {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName || "file";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => notify("Error downloading file:", error));
  } else {
    notify("Unsupported file type", "error");
  }
};

// chatbot
export const getMessageDateAndTime = (timestamp) => {
  const messageDate = new Date(timestamp);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (
    messageDate.getDate() === today.getDate() &&
    messageDate.getMonth() === today.getMonth() &&
    messageDate.getFullYear() === today.getFullYear()
  ) {
    return "Today";
  } else if (
    messageDate.getDate() === yesterday.getDate() &&
    messageDate.getMonth() === yesterday.getMonth() &&
    messageDate.getFullYear() === yesterday.getFullYear()
  ) {
    return "Yesterday";
  } else {
    // You can customize the format for older messages
    return `${messageDate.getFullYear()}-${(messageDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${messageDate.getDate().toString().padStart(2, "0")}`;
    // return `${messageDate.getDate()}/${messageDate.getMonth() + 1}`;
  }
};

export function NumberOfDataShown(pageNumber, pageSize, items) {
  if (pageNumber === 0) {
    return "1 to " + items;
  } else {
    return `${pageNumber * pageSize + 1}
       to 
      ${pageNumber * pageSize + Number(items)}`;
  }
}

// export function getFileType(file) {
//   const fileType = file.type; // MIME type of the file

//   const isSpreadsheet = ["spreadsheet", "excel", "xls", "csv"].some((keyword) =>
//     fileType.includes(keyword)
//   );

//   if (fileType.startsWith("image/")) {
//     return "image";
//   } else if (fileType.startsWith("video/")) {
//     return "video";
//   } else if (fileType === "application/pdf") {
//     return "pdf";
//   } else if (isSpreadsheet) {
//     return "excel";
//   } else if (fileType.startsWith("text/")) {
//     return "text";
//   } else {
//     return "unknown";
//   }
// }

export function getFileType(file) {
  const fileType = file?.type; // MIME type of the file
  const fileName = file?.name;

  // Function to extract extension from filename
  const getExtension = (filename) => {
    const lastDot = filename.lastIndexOf(".");
    return lastDot !== -1 ? filename.substring(lastDot + 1) : "";
  };

  // Get extension from file name
  const extension = getExtension(fileName).toLowerCase();

  // Map common file extensions to their types
  const extensionToType = {
    // Document types
    doc: "word",
    docx: "word",
    odt: "openDocumentText",
    pdf: "pdf",
    rtf: "rtf",
    txt: "text",
    md: "markdown",

    // Spreadsheet types
    xls: "excel",
    xlsx: "excel",
    ods: "openDocumentSpreadsheet",
    csv: "csv",

    // Presentation types
    ppt: "powerpoint",
    pptx: "powerpoint",
    odp: "openDocumentPresentation",

    // Compressed file types
    zip: "zip",
    rar: "rar",
    "7z": "7z",
    gz: "gzip",

    // Image types
    jpeg: "jpeg",
    jpg: "jpeg",
    png: "png",
    gif: "gif",
    bmp: "bmp",
    tiff: "tiff",
    svg: "svg",

    // Video types
    mp4: "mp4",
    avi: "avi",
    mkv: "mkv",
    mov: "mov",
    wmv: "wmv",
    flv: "flv",

    // Audio types
    mp3: "mp3",
    wav: "wav",
    aac: "aac",
    ogg: "ogg",
    flac: "flac",
  };

  // Use the extension to determine the file type
  if (extension in extensionToType) {
    return extensionToType[extension];
  } else if (fileType.startsWith("image/")) {
    return fileType.split("/")[1]; // Return image type as the extension
  } else if (fileType.startsWith("video/")) {
    return fileType.split("/")[1]; // Return video type as the extension
  } else if (fileType.startsWith("audio/")) {
    return fileType.split("/")[1]; // Return audio type as the extension
  } else {
    return "unknown"; // Default type if no match is found
  }
}

export function timeAgo(date) {
  if (!date) {
    return "";
  }

  const now = new Date();
  const targetDate = new Date(date);
  const seconds = Math.floor((now - targetDate) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Helper function to format the time in AM/PM
  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }

  // Today
  if (days === 0) {
    return formatAMPM(targetDate);
  }
  // Yesterday
  else if (days === 1) {
    return formatAMPM(targetDate);
  }
  // More than two days ago
  else {
    return formatAMPM(targetDate);
  }
}

export function formatInDDMMYY(date) {
  if (!date) {
    return "";
  }

  const now = new Date();
  const targetDate = new Date(date);
  const seconds = Math.floor((now - targetDate) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Format the date in DD-MM-YYYY format
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  // Today
  if (days === 0) {
    return "Today";
  }
  // Yesterday
  else if (days === 1) {
    return "Yesterday";
  }
  // More than two days ago
  else {
    return formatDate(targetDate);
  }
}

export const msgBorderRadius = (messages, i, u) => {
  const currentSenderId = messages[i]?.senderId;
  const previousSenderId = i > 0 ? messages[i - 1]?.senderId : null;
  const nextSenderId =
    i < messages.length - 1 ? messages[i + 1]?.senderId : null;

  const isFirstInSequence = currentSenderId !== previousSenderId;
  const isLastInSequence = currentSenderId !== nextSenderId;
  const isUserSender = currentSenderId == u;

  if (isFirstInSequence && isLastInSequence) {
    // Single message, round all corners.
    return "20px";
  } else if (isFirstInSequence) {
    // The styling for the first message in a sequence.
    return isUserSender ? "20px 20px 0px 20px" : "20px 20px 20px 0";
  } else if (isLastInSequence) {
    // The styling for the last message in a sequence.
    return isUserSender ? "20px 0px 20px 20px" : "0 20px 20px 20px";
  } else {
    // The styling for messages in the middle of a sequence.
    return isUserSender ? "20px 0 0 20px" : "0 20px 20px 0";
  }
};
