import React, { useState } from "react";
import {
  copyTextToClipboard,
  downloadFile,
  formatInDDMMYY,
  formateChatTime,
  msgBorderRadius,
  timeAgo,
} from "../../../utils/utils";
import Zoom from "react-medium-image-zoom";
import RenderFilePreview from "./RenderFilePreview";
import MessageDropdown from "../../../components/commonUI/MessageDropdown";
import { fileTypes } from "../../../utils/mockData";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const MessageView = ({
  type,
  text,
  u,
  timestamp,
  senderId,
  fileName,
  fileUrl,
  isRead,
  message,
  m,
  index,
  allMessages,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  let messageViewType;
  // common message component
  const tickMarks = (
    <font className="we">
      {timeAgo(timestamp)}{" "}
      {senderId == u?.id && (
        <>
          {isRead === true ? (
            <i
              className={"fa-solid fa-check-double"}
              style={{ color: "#33EC23" }}
            ></i>
          ) : (
            <i className={"fa-solid fa-check"}></i>
          )}
        </>
      )}
    </font>
  );

  if (type === "text") {
    messageViewType = (
      <p
        style={{
          whiteSpace: "pre-line",
          marginBottom: "15px",
        }}
      >
        {message}
        {tickMarks}
      </p>
    );
  } else if (fileTypes.image?.includes(type)) {
    messageViewType = (
      <>
        <Zoom>
          <img
            src={fileUrl}
            alt="Image"
            style={{
              width: "300px",
              borderTopLeftRadius: "11px",
              borderTopRightRadius: "11px",
              borderBottomLeftRadius: text ? "0" : "11px",
              borderBottomRightRadius: text ? "0" : "11px",
              height: "229px",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        </Zoom>
        {message && (
          <div className="imageTimeWalabhai">
            <div>
              {/*  image ke sath wala text hai */}
              {message && (
                <p
                  style={{
                    width: "300px",
                    padding: "10px 0",
                    whiteSpace: "pre-line",
                  }}
                >
                  {message}
                  {message && (
                    <font className={u.id == senderId ? "we" : "se"}>
                      {/* {timeAgo(m?.timestamp)}{" "} */}
                      {senderId == u.id && (
                        <>
                          {isRead === true ? (
                            <i
                              className={"fa-solid fa-check-double"}
                              style={{ color: "#33EC23" }}
                            ></i>
                          ) : (
                            <i className={"fa-solid fa-check"}></i>
                          )}
                        </>
                      )}
                    </font>
                  )}
                </p>
              )}
            </div>

            <font className={u.id == senderId ? "we" : "se"}>
              {timeAgo(m?.timestamp)}{" "}
              {senderId == u.id && (
                <>
                  {isRead === true ? (
                    <i
                      className={"fa-solid fa-check-double"}
                      style={{ color: "#33EC23" }}
                    ></i>
                  ) : (
                    <i className={"fa-solid fa-check"}></i>
                  )}
                </>
              )}
            </font>

            <MessageDropdown
              // onEditClick={() => setDivShow(true)}
              onDeleteClick={() => console.log("Delete clicked")}
              rev={u.id}
              sen={senderId}
              copyText={() => copyTextToClipboard(fileUrl || text)}
            />
          </div>
        )}
      </>
    );
  } else if (fileTypes.video.includes(type)) {
    messageViewType = (
      <video
        controls
        src={fileUrl}
        style={{ maxWidth: "300px", maxHeight: "100%" }}
      />
    );
  } else if (fileTypes.audio.includes(type)) {
    messageViewType = (
      <AudioPlayer
        // autoPlay
        src={fileUrl}
        onPlay={(e) => console.log("onPlay")}
        // other props here
        autoPlayAfterSrcChange={false}
        showJumpControls={false}
        style={{
          minWidth: "300px",
          background: "transparent",
          boxShadow: "none",
        }}
      />
    );
  } else if (fileTypes.document.includes(type)) {
    messageViewType = (
      <>
        <div className={u?.id == senderId ? "myselfDoc" : " otherDoc"}>
          <div className="file bg">
            <div className="bg">
              <i
                className="fa-regular fa-file"
                style={{
                  fontSize: "3.5rem",
                  color: "whitesmoke",
                }}
                onClick={() => setShowPreview(!showPreview)}
              ></i>

              <MessageDropdown
                onEditClick={() => console.log(true)}
                onDeleteClick={() => console.log("Delete clicked")}
                rev={u.id}
                sen={senderId}
                copyText={() => copyTextToClipboard(fileUrl || text)}
                fileWalaDown={fileName}
              />
              <div
                className="nameNBtn"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "50px",
                  color: "grey",
                  gap: "5px",
                }}
              >
                <button
                  className={u?.id == senderId ? "myself" : "other"}
                  onClick={() =>
                    downloadFile({
                      fileUrl,
                      fileName,
                    })
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="fa-solid fa-download"
                    style={{ color: "#545eb3", fontSize: "2rem" }}
                  ></i>
                </button>

                <p
                  style={{
                    textAlign: "left",
                    padding: "0",
                    marginBottom: "0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {fileName || "N/A"}
                </p>
              </div>

              {/* </a>{" "} */}
              <font className="we">
                {timeAgo(timestamp)}{" "}
                {senderId == u.id && (
                  <>
                    {" "}
                    {isRead === true ? (
                      <i
                        className={"fa-solid fa-check-double"}
                        style={{ color: "#33EC23" }}
                      ></i>
                    ) : (
                      <i className={"fa-solid fa-check"}></i>
                    )}
                  </>
                )}
              </font>
            </div>
            {text && <p>{text}</p>}
          </div>
        </div>
      </>
    );
  } else if (fileTypes.compressed.includes(type)) {
    messageViewType = (
      <div className={u?.id == senderId ? "myselfDoc" : " otherDoc"}>
        <div className="file bg">
          <div className="bg">
            <i
              className="fa-regular fa-file-zipper"
              style={{
                fontSize: "3.5rem",
                color: "whitesmoke",
              }}
              onClick={() => setShowPreview(!showPreview)}
            ></i>

            <MessageDropdown
              onEditClick={() => console.log(true)}
              onDeleteClick={() => console.log("Delete clicked")}
              rev={u.id}
              sen={senderId}
              copyText={() => copyTextToClipboard(fileUrl || text)}
              fileWalaDown={fileName}
            />
            <div
              className="nameNBtn"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "50px",
                color: "grey",
                gap: "5px",
              }}
            >
              <button
                className={u?.id == senderId ? "myself" : "other"}
                onClick={() =>
                  downloadFile({
                    fileUrl,
                    fileName,
                  })
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  className="fa-solid fa-download"
                  style={{ color: "#545eb3", fontSize: "2rem" }}
                ></i>
              </button>

              <p
                style={{
                  textAlign: "left",
                  padding: "0",
                  marginBottom: "0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {fileName || "N/A"}
              </p>
            </div>

            {/* </a>{" "} */}
            <font className="we">
              {timeAgo(timestamp)}{" "}
              {senderId == u.id && (
                <>
                  {" "}
                  {isRead === true ? (
                    <i
                      className={"fa-solid fa-check-double"}
                      style={{ color: "#33EC23" }}
                    ></i>
                  ) : (
                    <i className={"fa-solid fa-check"}></i>
                  )}
                </>
              )}
            </font>
          </div>
          {text && <p>{text}</p>}
        </div>
      </div>
    );
  } else {
    messageViewType = (
      <div className={u?.id == senderId ? "myselfDoc" : " otherDoc"}>
        <div className="file bg">
          <div className="bg">
            <i
              className="fa-solid fa-file-circle-question"
              style={{
                fontSize: "3.5rem",
                color: "whitesmoke",
              }}
              onClick={() => setShowPreview(!showPreview)}
            ></i>

            <MessageDropdown
              onEditClick={() => console.log(true)}
              onDeleteClick={() => console.log("Delete clicked")}
              rev={u.id}
              sen={senderId}
              copyText={() => copyTextToClipboard(fileUrl || text)}
              fileWalaDown={fileName}
            />
            <div
              className="nameNBtn"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "50px",
                color: "grey",
                gap: "5px",
              }}
            >
              <button
                className={u?.id == senderId ? "myself" : "other"}
                onClick={() =>
                  downloadFile({
                    fileUrl,
                    fileName,
                  })
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  className="fa-solid fa-download"
                  style={{ color: "#545eb3", fontSize: "2rem" }}
                ></i>
              </button>

              <p
                style={{
                  textAlign: "left",
                  padding: "0",
                  marginBottom: "0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {fileName || "N/A"}
              </p>
            </div>

            {/* </a>{" "} */}
            <font className="we">
              {timeAgo(timestamp)}{" "}
              {senderId == u.id && (
                <>
                  {" "}
                  {isRead === true ? (
                    <i
                      className={"fa-solid fa-check-double"}
                      style={{ color: "#33EC23" }}
                    ></i>
                  ) : (
                    <i className={"fa-solid fa-check"}></i>
                  )}
                </>
              )}
            </font>
          </div>
          {text && <p>{text}</p>}
        </div>
      </div>
    );
  }

  return (
    <>
      {showPreview && (
        <RenderFilePreview
          url={fileUrl}
          type={type}
          setIsOpen={setShowPreview}
          isOpen={showPreview}
        />
      )}
      {index === 0 ||
        (formatInDDMMYY(timestamp) !==
          formatInDDMMYY(allMessages[index - 1].timestamp) && (
          <>
            <img
              src="/icons-images/lineHRBG.svg"
              style={{ position: "relative", top: "22px" }}
            />
            <h3 class="title-around-span">
              <span>{formatInDDMMYY(timestamp)}</span>
            </h3>
          </>
        ))}
      {/* Rendering Text Only here */}
      <li key={index}>
        <MessageDropdown
          onEditClick={() => console.log(true)}
          onDeleteClick={() => console.log("Delete clicked")}
          rev={u.id}
          sen={senderId}
          m={m}
        />
        <div
          className={u?.id == senderId ? "sent" : "replies"}
          style={{
            padding: type === "image" ? 0 : "",
            borderRadius: type === "image" ? "20px" : "",
          }}
        >
          {messageViewType}
        </div>
      </li>
    </>
  );
};

export default MessageView;
