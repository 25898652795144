import React from "react";
import "../../styles/chat.css";
import { copyTextToClipboard, downloadFile } from "../../utils/utils";
import { fileTypes } from "../../utils/mockData";

const MessageDropdown = ({
  onEditClick,
  onDeleteClick,
  rev,
  sen,
  imageDownload,
  AllData,
  fileWalaDown,
  m,
}) => {
  return (
    <>
      {fileWalaDown ? null : (
        <>
          <i
            onClick={onEditClick}
            className={`fa-solid fa-ellipsis-vertical menubtn  ${
              rev == sen ? "sent" : "replies"
            }`}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            id={rev == sen ? "dddd" : "cccc"}
          ></i>
          <ul className="dropdown-menu">
            {m?.message && (
              <span>
                <b
                  className="dropdown-item"
                  onClick={() => copyTextToClipboard(m?.message)}
                >
                  Copy
                </b>
              </span>
            )}

            {m?.fileUrl && (
              <span className="cursor-pointer" style={{ cursor: "pointer" }}>
                <b
                  className="dropdown-item"
                  onClick={() =>
                    downloadFile({
                      fileUrl: m?.fileUrl,
                      fileName: m?.fileName,
                    })
                  }
                >
                  Save to "Downloads"
                </b>
              </span>
            )}

            {/* <span>
          <a className="dropdown-item" onClick={onDeleteClick}>
            Edit
          </a>
        </span>
        <span>
          <a className="dropdown-item" onClick={onDeleteClick}>
            Delete 
          </a>
        </span> */}
          </ul>
        </>
      )}
    </>
  );
};

export default MessageDropdown;
