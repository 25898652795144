import React from "react";
import { fileTypes } from "../../../utils/mockData";

const FilePreview = ({ file, type, handleSendMessage }) => {
  if (fileTypes.image.includes(type)) {
    return (
      <img
        src={URL.createObjectURL(file)}
        alt="Preview"
        style={{ maxWidth: "200px" }}
      />
    );
  } else if (fileTypes.video.includes(type)) {
    return (
      <img
        src="https://icons.veryicon.com/png/o/file-type/file-type-1/mp4-icon.png"
        width={"60px"}
        height={"50px"}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage(e);
          }
        }}
      />
    );
  } else if (fileTypes.audio.includes(type)) {
    return (
      <img
        width={"60px"}
        height={"50px"}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage(e);
          }
        }}
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqL-LtGG1_hCzyQRGLEWrldaQhT7bk1BHZDA&usqp=CAU"
      />
    );
  } else if (fileTypes.document.includes(type)) {
    return (
      <img
        src={"https://www.computerhope.com/jargon/d/doc.png"}
        width={"60px"}
        height={"50px"}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage(e);
          }
        }}
      />
    );
  } else if (["zip", "gz", "bz2", "rar", "7z"].includes(type)) {
    return (
      <img
        src={"/icons-images/zip.png"}
        width={"60px"}
        height={"50px"}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage(e);
          }
        }}
      />
    );
  } else {
    return <p>No preview available</p>;
  }
};

export default FilePreview;
