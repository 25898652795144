import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCurrentUserLT,
  getToken,
  makeApiRequest,
  notify,
} from "../../../utils/utils";
import { api } from "../../../network-request/api";

export const getAllChatsAction = createAsyncThunk("getAllChats", async () => {
  const id = getCurrentUserLT()?.id;
  return await makeApiRequest(api.chatList + `?userId=${id}`, {
    token: getToken(),
  });
});

const initialState = {
  chatList: [],
  loading: false,
  error: "",
  message: "",
  success: false,
};

const getAllChatsSlice = createSlice({
  name: "getAllChats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllChatsAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(getAllChatsAction.fulfilled, (state, { payload }) => {
        state.chatList = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(getAllChatsAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default getAllChatsSlice.reducer;
