export const hourData = [
  {
    id: 1,
    name: "1 hours",
  },
  {
    id: 2,
    name: "2 hours",
  },
  {
    id: 3,
    name: "3 hours",
  },
  {
    id: 4,
    name: "4 hours",
  },
  {
    id: 5,
    name: "5 hours",
  },
  {
    id: 6,
    name: "6 hours",
  },
  {
    id: 7,
    name: "7 hours",
  },
  {
    id: 8,
    name: "8 hours",
  },
  {
    id: 9,
    name: "9 hours",
  },
  {
    id: 10,
    name: "10 hours",
  },
  {
    id: 11,
    name: "11 hours",
  },
  {
    id: 12,
    name: "12 hours",
  },
];

export const fileTypes = {
  image: ["jpeg", "jpg", "png", "gif", "bmp", "tiff", "svg", "png", "image"],
  video: ["mp4", "avi", "mkv", "mov", "wmv", "flv"],
  audio: ["mp3", "wav", "aac", "ogg", "flac"],
  document: ["word", "excel", "powerpoint", "csv", "pdf", "document"],
  compressed: ["zip", "rar", "7z", "gzip"],
};
