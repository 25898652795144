// =================== PROPER WORK CODE ==========================================

import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { handleFullName } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getChatBotMessageData } from "../../../redux/slice/chatBot/chatBotSlice";
// import EmojiPicker from "emoji-picker-react";
import { getFirstLetterName } from "../../../utils/utils";
import EmojiPicker from "emoji-picker-react";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

import FilePreview from "./FilePreview";

import MessageView from "./MessageView";
import AddUser from "../../Users/AddUser";
import { getUserByIdAction } from "../../../redux/slice/users/getUserByIdSlice";

const Messaging = ({
  chat,
  setMessage,
  messages,
  message,
  handleSendMessage,
  senderId,
  u,
  handleEmoji,
  viewFile,
  handleFileChange,
  setViewFile,
  loading,
  fileType,
  onlineUsers,
  setMessages,
}) => {
  const dispatch = useDispatch();

  const messagesContainerRef = useRef(null);
  const [emojiShowing, setEmojiShowing] = useState(false); // this state for hide and show
  const emojiPickerRef = useRef(null);
  const [isOpen, setIsopen] = useState(false);

  const isOnline = onlineUsers?.includes(chat.userId);

  const selectedUserId = chat.userId; // Assuming chat object has an 'id' property
  const oldMessages = useSelector((item) => item.getChatBot.dataMesaage);

  let allMessages = [...oldMessages, ...messages];

  // handle emoji add on chat messgae same as whatsapp
  const handleClickedEmoji = (e) => {
    handleEmoji(e);
    setMessage((prev) => prev + e.emoji);
  };

  // chat message
  useEffect(() => {
    if (selectedUserId) {
      dispatch(
        getChatBotMessageData({
          senderID: senderId,
          receiverID: selectedUserId,
        })
      );
    }
    setMessages([]);
  }, [senderId, selectedUserId]);

  // chat end me

  // Selected Message Particular by ID
  const selectedUserMessages = messages.filter(
    (eleV) =>
      eleV.senderId == selectedUserId || eleV.receiverId == selectedUserId
  );

  // No Socket affecting code below
  // No Socket affecting code below

  // Function to close emoji picker when clicking outside
  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setEmojiShowing(false);
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the messages div
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [allMessages, message, emojiShowing]);

  useEffect(() => {
    // Scroll to the bottom of the messages div whenever messages are updated
    scrollToBottom();
  }, [messages]);

  const toggleDivShow = (messageId) => {
    // setDivShow((prevId) => (prevId === messageId ? null : messageId));
    setViewFile(null); // Reset the viewFile state to null
    setMessage("");
  };

  // to stay at bottom of the message list
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  // to stay at bottom of the message list
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div className="content">
        <h1>{/* {fileExists ? "hai":"nhi"} */}</h1>
        <div className="contact-profile">
          <div className="row">
            <div className="col-12">
              <div className="users">
                <span
                  class={
                    isOnline ? "contact-status online" : "contact-status busy"
                  }
                ></span>
                {chat?.profileImage ? (
                  <>
                    <img src={chat?.profileImage} />
                  </>
                ) : (
                  <>
                    {/* <img src={"/icons-images/profile1.png"} /> */}
                    <div className="firstLettermm">
                      <p>{getFirstLetterName(chat.fname + " " + chat.lname)}</p>
                    </div>
                  </>
                )}
                <h3
                  onClick={() => {
                    setIsopen(true);
                    dispatch(getUserByIdAction(chat.userId));
                  }}
                >
                  {handleFullName({
                    fname: chat.fname,
                    lname: chat.lname,
                  })}
                  {/* <i class="fa-solid fa-gear"></i> */}
                  <img
                    src="/icons-images/userICON.svg"
                    style={{ width: "18px", cssFloat: "none", margin: "0 6px" }}
                  />
                  <br />
                  <p className={isOnline ? "online" : "offline"}>
                    {isOnline ? "Online" : "Offline"}
                  </p>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="messages" ref={messagesContainerRef}>
          <ul>
            <ul>
              {allMessages?.map((m, i) => {
                return (
                  <MessageView
                    {...m}
                    key={m.id}
                    u={u}
                    m={m}
                    index={i}
                    allMessages={allMessages}
                  />
                );
              })}
            </ul>
          </ul>

          {emojiShowing ? (
            <>
              <div className="emojiPicker" ref={emojiPickerRef}>
                <EmojiPicker onEmojiClick={handleClickedEmoji} />
              </div>
            </>
          ) : null}
        </div>

        <form className="chat-box" onSubmit={handleSendMessage}>
          <div className="">
            <div className="icon">
              <NavLink>
                {" "}
                <i
                  class="fa-regular fa-face-smile"
                  onClick={() => setEmojiShowing(!emojiShowing)}
                ></i>
              </NavLink>

              <a>
                <label htmlFor="file-input4">
                  <i class="fa-solid fa-paperclip"></i>
                </label>
                <input
                  id="file-input4"
                  type="file"
                  // accept="image/*, video/mp4,  audio/mp3, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  // accept="image/*, video/mp4, audio/mp3, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
                  accept="image/*, video/mp4, audio/mp3, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip, application/vnd.android.package-archive"
                  className="d-none"
                  onChange={handleFileChange}
                  // multiple="multiple"
                />
              </a>
            </div>
          </div>

          <div className="chatinputwidth">
            <div class="search imageWithSearch">
              {viewFile && (
                <>
                  <i
                    className="fa-solid fa-xmark"
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      color: "#000",
                      top: "-7px",
                      background: "#fff",
                      padding: "4px",
                      fontSize: "11px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleDivShow("1")}
                  ></i>
                  <div
                    className="filePreview"
                    style={{
                      maxHeight: "200px",
                      maxWidth: "200px",
                      marginBottom: "2rem",
                    }}
                  >
                    <FilePreview
                      file={viewFile}
                      type={fileType}
                      handleSendMessage={handleSendMessage}
                    />
                  </div>
                </>
              )}
              <textarea
                type="text"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type a message"
                value={message}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage(e);
                  }
                }}
              />
            </div>
          </div>

          <div className="">
            <button type="submit" className="icon sendBtn">
              {/* <NavLink> */}
              {/* <i class="fa-solid fa-paper-plane"></i> */}
              {loading ? (
                "loading..."
              ) : (
                <img src="/icons-images/whatsappIcon.svg" width={"20px"} />
              )}

              {/* send */}
              {/* </NavLink> */}
            </button>
          </div>
        </form>
      </div>

      <AddUser
        isOpen={isOpen}
        onClose={() => setIsopen(false)}
        title="View Profile"
        setIsOpen={setIsopen}
        disabled={true}
        type="view"
      />
    </>
  );
};

export default Messaging;
