import React, { useEffect, useRef, useState } from "react";
import "../../styles/chat.css";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import {
  formateTimeWithChatEnd,
  getCurrentUserLT,
  getFileType,
  getFirstLetterName,
  getToken,
  handleFullName,
  makeApiRequest,
  notify,
} from "../../utils/utils";
import Messaging from "./Components/Messaging";
import NoChatSelected from "./Components/NoChatSelected";
import { useDispatch, useSelector } from "react-redux";
import { getChatBotMessageData } from "../../redux/slice/chatBot/chatBotSlice";
import axios from "axios";
import { getSearchChatUser } from "../../redux/slice/chatBot/getUserSearchSlice";
import { getAllChatsAction } from "../../redux/slice/chatBot/getChatListSlice";
import { api } from "../../network-request/api";

const Chatbot = () => {
  const dispatch = useDispatch();
  const loc = useLocation();
  const senderId = getCurrentUserLT()?.id;
  const u = useSelector((state) => state.userByToken.user);
  let socket = useRef();

  const [friendbox, setTab] = useState(true);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ userId: null });
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [emoji, setEmoji] = useState();
  const [documentRef, setDocumentRef] = useState();
  const [viewFile, setViewFile] = useState("");
  const [fileData, setFileData] = useState();
  const [fileType, setfileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const chatList = useSelector((state) => state.chatList);

  const friendShow = () => setTab(!friendbox);
  const handleTyping = () => {
    setIsTyping(true);

    // Clear typing indicator after a delay (e.g., 2000 milliseconds)
    setTimeout(() => {
      setIsTyping(false);
    }, 2000);
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredUserList = chatList.chatList.filter(
    (user) =>
      user.fname?.toLowerCase().includes(searchInput.toLowerCase()) ||
      user.lname?.toLowerCase().includes(searchInput.toLowerCase())
  );

  useEffect(() => {
    if (!searchInput) {
      setUserList(chatList.chatList);
    } else if (searchInput) {
      setUserList(filteredUserList);
    }
  }, [chatList.success, searchInput]);

  useEffect(() => {
    dispatch(getAllChatsAction());
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileType = getFileType(file);
    if (fileType) {
      setfileType(fileType);
      setViewFile(file);
    } else {
      setfileType("unknown");
    }

    setIsTyping(false);
  };

  // ==== proper work  code
  useEffect(() => {
    const res = io(process.env.REACT_APP_BASE_URL, {
      auth: { token: getToken() },
    });
    socket.current = res;
    socket.current.emit("addUser", senderId);

    // this is a clean-up function for disconnected chat
    return () => {
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.current.on("getMessage", (message) => {
        setMessages((existingMessages) => [...existingMessages, message]);
        dispatch(getAllChatsAction());
      });
    }
    return () => {
      socket.current.off("getMessage");
    };
  }, []);

  // hasRead is sending read receipt to receiver
  useEffect(() => {
    if (socket.current) {
      selectedUser &&
        socket.current.emit("hasRead", {
          senderId: senderId,
          receiverId: selectedUser.userId,
          hasRead: true,
        });

      socket.current.on("getUsers", (data) => {
        setOnlineUsers(data);
      });
    }
  }, [selectedUser]);

  const uploadFile = async () => {
    try {
      setLoading(true);
      setfileType(getFileType(viewFile));
      const formData = new FormData();
      formData.append("chatImage", new Blob([viewFile]), viewFile.name);

      // Make API request to upload the file using Axios
      const response = await axios.post(api.uploadChatFile, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response && response.data) {
        const { fileUrl, fileName } = response.data;

        // Set fileData as an object with fileUrl and fileName properties
        setFileData({ fileUrl, fileName });

        // Return an object with both fileUrl and fileName
        return { fileUrl, fileName };
      } else {
        // Handle server error
        console.error("File upload failed:", response.statusText);
      }
    } catch (error) {
      setLoading(false);
      // Handle network error or other issues
      console.error("Error uploading file:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Sending messages here
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "" && !viewFile) {
      notify("Please enter a message before sending.", "error");
      return;
    }

    if (viewFile) {
      // File upload logic
      const result = await uploadFile();
      console.log(result);
      const messageData = {
        senderId: senderId,
        receiverId: selectedUser.userId,
        message: newMessage,
        fileUrl: result?.fileUrl,
        fileName: result?.fileName,
        type: fileType,
      };
      socket.current.emit("sendMessage", messageData);
      setViewFile("");
      setNewMessage("");
    } else {
      // Message send logic
      const messageData = {
        senderId: senderId,
        receiverId: selectedUser.userId,
        // fileUrl: fileData,
        message: newMessage,
        type: "text",
        timestamp: new Date().getTime(),
      };
      socket.current.emit("sendMessage", messageData);
      // setMessages((existingMessages) => [...existingMessages, messageData]);
      setNewMessage("");
    }
    dispatch(getAllChatsAction());
  };
  const fullName =
    u?.fname || u?.lname ? u?.fname + " " + u?.lname : "Loading...";

  return (
    <div className="frame">
      <div className="sidepanel">
        <div class="top-profile">
          <div class="users">
            <img src={u.profileImage || "/icons-images/Profileimage.png"} />
            <h3>
              {handleFullName({ fullName, fname: u?.fname, lname: u?.lname }) ||
                "Loading..."}
              <br />
            </h3>
          </div>
        </div>
        <div className="search">
          <label for="">
            <i className="fa fa-search" aria-hidden="true"></i>
          </label>
          <input
            type="search"
            placeholder="Search Here..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyUp={handleSearch}
          />{" "}
          {searchInput && (
            <i
              class="fa-solid fa-xmark x"
              onClick={() => setSearchInput("")}
            ></i>
          )}
        </div>
        <hr />

        <div className="scrollbar">
          <div className="chatGroup">
            {userList.length === 0 ? (
              <>
                {/* <h1
                  style={{
                    fontSize: "30px",
                    color: "#afa4a4",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "66vh",
                  }}
                >
                  No User Found
                </h1> */}

                <div
                  class="alert alert-danger"
                  role="alert"
                  style={{
                    width: "90%",
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "200px",
                  }}
                >
                  No User Found
                </div>
              </>
            ) : (
              <>
                <h2>
                  {/* Total Friends:- {userList?.length} */}
                  <span onClick={friendShow}>
                    {/* <i class="fa-solid fa-chevron-down"></i> */}
                  </span>
                </h2>
                <div className="contacts">
                  <ul>
                    {userList?.map((u) => {
                      const lm = u.lastMessage?.message;
                      const lastMessage =
                        lm?.length > 12 ? lm.slice(0, 12) + "..." : lm;

                      const isOnline = onlineUsers.includes(u.userId);
                      return (
                        <li
                          className={
                            u.userId === selectedUser?.userId
                              ? "contact active"
                              : "contact"
                          }
                          onClick={() => setSelectedUser(u)}
                        >
                          <div className="wrap">
                            <span
                              className={
                                isOnline
                                  ? "contact-status online"
                                  : "contact-status busy"
                              }
                            ></span>
                            {u.profileImage ? (
                              <>
                                {" "}
                                <img
                                  src={
                                    u.profileImage ||
                                    `${process.env.PUBLIC_URL}/icons-images/profile1.png`
                                  }
                                />
                              </>
                            ) : (
                              <>
                                {" "}
                                <div className="firstLetter">
                                  <p>
                                    {getFirstLetterName(
                                      u.fname + " " + u.lname
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                            <div className="meta w-100 ">
                              <h4 className="name position-relative">
                                {handleFullName({
                                  fname: u.fname,
                                  lname: u.lname,
                                })}
                                <p
                                  class="badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success text-light  m-0"
                                  style={{ right: "0" }}
                                >
                                  {u?.unreadMessagesCount || ""}
                                </p>
                              </h4>
                              <p>{lastMessage}</p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            )}

            <div className="bottom-bar">
              <div className="row">
                <div className="col-6">
                  <NavLink to="/dashboard">
                    <button className="addcontact">
                      <i class="fa-solid fa-house"></i> <br />
                      <span>Dashboard</span>
                    </button>
                  </NavLink>
                </div>
                <div className="col-6">
                  <NavLink to="/chatbot">
                    {/* <NavLink to="/chatuser"> */}
                    <button
                      className={
                        loc.pathname === "/chatbot" ? "highLLired" : " settings"
                      }
                    >
                      <i class="fa-regular fa-comment-dots"></i>
                      <br />
                      <span>Chats</span>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* messaging */}
      {selectedUser.userId ? (
        <Messaging
          {...{
            u,
            handleSendMessage,
            messages,
            loading,
            fileData,
            uploadFile,
            userList,
            handleFileChange,
            setViewFile,
            viewFile,
            setDocumentRef,
            documentRef,
            handleTyping,
            setIsTyping,
            isTyping,
            fileType,
            setMessages,
            onlineUsers,
          }}
          setMessage={setNewMessage}
          message={newMessage}
          senderId={senderId}
          chat={selectedUser}
          handleEmoji={setEmoji}
          getEmoji={emoji}
        />
      ) : (
        <NoChatSelected />
      )}
    </div>
  );
};

export default Chatbot;

// if (file) {
//   console.log("File Type:", file.type); // Add this line for debugging

//   if (file.type.startsWith("image/")) {
//     // Handle image files
//     setViewFile(file);
//   } else if (file.type === "application/pdf") {
//     // Handle PDF files
//     setViewFile(file);
//   } else if (
//     file.type === "application/msword" ||
//     file.type ===
//       "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//   ) {
//     // Handle Word files
//     setViewFile(file);
//   } else if (
//     file.type === "application/vnd.ms-excel" ||
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
//   ) {
//     // Handle Excel files
//     setViewFile(file);
//   } else if (file.type === "application/zip") {
//     // Handle Excel files
//     setViewFile(file);
//   } else if (file.type === "application/vnd.android.package-archive") {
//     // Handle Excel files
//     setViewFile(file);
//   } else {
//     // Unsupported file type
//     setViewFile(null);
//     notify("Unsupported file type", "error");
//   }
// } else {
//   // No file selected
//   setViewFile(null);
// }

// const handleSendMessage = async (e) => {
//   e.preventDefault();
//   if (newMessage.trim() === "" && !viewFile) {
//     notify("Please enter a message before sending.", "error");
//     return;
//   }
//   // const fileUrl = await uploadFile(viewFile);
//   // const  fileUrl  = await uploadFile();
//   // console.log("dddddddddddddd",fileUrl);

//   if (viewFile) {
//     // File upload logic
//     const result = await uploadFile();
//     console.log("FILE FILE", result);
//     const messageData = {
//       senderId: senderId,
//       receiverId: selectedUser.userId,
//       // message: newMessage,
//       fileUrl: result?.fileUrl,
//       fileName: result?.fileName,
//     };
//     socket.current.emit("sendMessage", messageData);

//     // if (senderId === u.id) {
//     //   setMessages((existingMessages) => [
//     //     ...existingMessages,
//     //     {
//     //       ...messageData,
//     //       timestamp: new Date().toISOString(),
//     //     },
//     //   ]);
//     // }
//     setViewFile("");
//     setNewMessage("");
//   } else {
//     // Message send logic
//     const messageData = {
//       senderId: senderId,
//       receiverId: selectedUser.userId,
//       message: newMessage,
//       // fileUrl: fileData,
//     };

//     // const messageData = {
//     //   senderId: senderId,
//     //   receiverId: selectedUser.userId,
//     //   message: newMessage,
//     //   fileUrl: fileData,
//     // };

//     socket.current.emit("sendMessage", messageData);
//     // socket.current.emit("sendMessage", formData);

//     // if (senderId == u.id) {
//     //   setMessages((existingMessages) => [
//     //     ...existingMessages,
//     //     {
//     //       ...messageData,
//     //       timestamp: new Date().toISOString(),
//     //     },
//     //   ]);
//     // }

//     setNewMessage("");
//   }
// };
