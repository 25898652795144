import React from "react";
import Modal from "../../../components/commonUI/Modal";
import useWindowSize from "../../../utils/Hooks/useWindowSize";

const RenderFilePreview = ({ url, type, isOpen, setIsOpen }) => {
  const renderFilePreview = () => {
    switch (type) {
      case "jpeg":
      case "jpg":
      case "png":
      case "gif":
      case "bmp":
      case "tiff":
      case "svg":
      case "png":
        return (
          <img
            src={url}
            alt="File Preview"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        );
      case "mp4":
      case "avi":
      case "mkv":
      case "mov":
      case "wmv":
      case "flv":
        return (
          <video
            controls
            src={url}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        );
      case "mp3":
      case "wav":
      case "aac":
      case "ogg":
      case "flac":
        return <audio controls src={url} style={{ width: "100%" }} />;
      case "pdf":
        return (
          <iframe
            src={url}
            style={{ width: "100%", height: "100%" }}
            frameBorder="0"
          ></iframe>
        );
      case "text":
      case "markdown":
        return (
          <iframe
            src={`data:text/plain;charset=utf-8,${encodeURIComponent(url)}`}
            style={{ width: "100%", height: "100%" }}
            frameBorder="0"
          ></iframe>
        );
      case "word":
      case "excel":
      case "powerpoint":
        // Google Docs Viewer can preview DOCX, XLSX, PPTX files but requires publicly accessible URLs.
        return (
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(
              url
            )}&embedded=true`}
            style={{ width: "100%", height: "100%" }}
            frameBorder="0"
          ></iframe>
        );
      default:
        return <p>No preview available for this file type.</p>;
    }
  };

  const windowSize = useWindowSize();

  let left = windowSize > 768 ? "calc(50% - 600px)" : "2.5%";
  let width = windowSize > 768 ? "1200px" : "95%";

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      style={{
        width,
        top: "10vh",
        maxHeight: "auto",
        height: "80vh",
        background: "white",
        left,
        paddingTop: "3rem",
        overflow: "auto",
      }}
    >
      <div className="top">
        <div className="heading">
          <h3>
            <span>View file</span>
          </h3>
        </div>
      </div>
      {renderFilePreview()}
    </Modal>
  );
};

export default RenderFilePreview;
