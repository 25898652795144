import React from "react";

const NoInternet = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>No Internet Connection Found 😒</h1>
      <p style={styles.message}>
        Please check your internet connection and try again.
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80px",
    width: "100%",
    backgroundColor: "white",
    paddingTop: "20px",
  },
  heading: {
    fontSize: "26px",
    color: "black",
    marginBottom: "6px",
    fontWeight: "900",
  },
  message: {
    fontSize: "10px",
    color: "grey",
    textAlgn: "center",
    maxWidth: "90%",
  },
};

export default NoInternet;
